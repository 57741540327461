import { Directive, ElementRef, Input, OnInit, Renderer2, AfterViewInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[portalClickableChipLink]',
})
export class ClickableChipDirective implements OnInit, AfterViewInit {
  @Input() portalClickableChipLink: string;
  originalText?: HTMLSpanElement;
  @HostListener('click', ['$event']) onClick(_event: MouseEvent) {
    if (this.originalText instanceof HTMLSpanElement) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([this.portalClickableChipLink], { queryParams: { returnUrl: this.router.url } }),
      );
      window.open(url, '_blank');
    }
  }

  constructor(
    private readonly element: ElementRef,
    private readonly renderer: Renderer2,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.originalText = this.element.nativeElement.querySelector('.chip-label');
  }

  ngAfterViewInit(): void {
    if (!this.originalText) {
      this.originalText = this.element.nativeElement.querySelector('.chip-label');
    }
    if (this.originalText?.innerText?.length) {
      this.wrapTextInLink();
    }
  }

  private wrapTextInLink(): void {
    const link = this.renderer.createElement('a');
    const text: HTMLSpanElement = this.renderer.createElement('span');
    text.innerHTML = this.originalText.innerHTML;
    this.renderer.setAttribute(link, 'href', this.portalClickableChipLink);
    this.renderer.setAttribute(link, 'target', '_blank');
    this.renderer.addClass(link, 'chip-label');
    this.renderer.appendChild(link, text);
    this.originalText.replaceWith(link);
  }
}
