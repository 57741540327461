import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { LegalSellerShared } from '@portal/models/legalSellerShared';
import { CommonBuyerShared } from '@portal/models/commonBuyerShared';
import { authClientContext, usersClientContext } from '@portal/api-endpoints';
import { replaceId, getHttpParams } from '@portal/core';
import { ActionResultLegalSellerShared } from '@portal/models/actionResultLegalSellerShared';
import { map } from 'rxjs/operators';
import { ActionResultCommonBuyerShared } from '@portal/models/actionResultCommonBuyerShared';

@Injectable({ providedIn: 'root' })
export class InternalCallbackService {
  constructor(private readonly http: HttpClient) {}

  loadSeller(id: number): Observable<LegalSellerShared> {
    return this.http
      .get<ActionResultLegalSellerShared>(replaceId(authClientContext.seller.legalSellerById, id))
      .pipe(map((result: ActionResultLegalSellerShared) => result.value));
  }

  loadBuyer(id: number): Observable<CommonBuyerShared> {
    return this.http
      .get<ActionResultCommonBuyerShared>(replaceId(usersClientContext.commonBuyer.getCommonBuyer, id), {
        params: getHttpParams({ includeUser: true }),
      })
      .pipe(map((result: ActionResultCommonBuyerShared) => result.value));
  }
}
