import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InternalCallbackComponent } from './internal-callback.component';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
  {
    path: '',
    component: InternalCallbackComponent,
  },
];
@NgModule({
  declarations: [InternalCallbackComponent],
  imports: [MatProgressBarModule, RouterModule.forChild(routes)],
  exports: [InternalCallbackComponent],
})
export class InternalCallbackModule {}
